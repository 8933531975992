
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import TableFilterable from '@/components/reports-v2/components/filterables/TableFilterable.vue';
import FilterWidget from '@/components/reports-v2/components/codedWidgets/FilterWidget.vue';
import { FilteredDatabase } from '@/worker/fd/FilteredDatabase';
import {
	PermissionsGroup,
	PurchaseRequestOrder,
	SalesOrderDetailCard,
	StockDbCard,
	StockTransCard,
} from '@/store/models.def';
import moment from 'moment';
import gdbx from '@/store/modules/gdbx';
import { roundToFixed } from '@/util/number';
import Checkbox from 'primevue/checkbox';

@Component({
	components: { TableFilterable, Checkbox },
})
export default class ReorderTable extends FilterWidget {
	public get permissionIds(): PermissionsGroup[] {
		return ['stocks'];
	}

	public isIncludeInactive: boolean = false;
	public isOnlyFilledNewReorder: boolean = false;
	public isShowDetails: boolean = false;

	public filterIds: Array<
		| 'date'
		| 'dateAsOf'
		| 'dateRange'
		| 'stockItems'
		| 'agents'
		| 'customers'
		| 'suppliers'
	> = [];

	public tableItems: any[] = [];
	public tableFields: any[] = [];

	public get exportFileName() {
		const formattedDate = moment(this.selectedAsOfDate).format('DD MMM YY');

		return 'Reorder Analysis(System)' + '_' + 'As Of ' + formattedDate;
	}

	public get dateFormatted() {
		return [this.selectedAsOfDate];
	}

	public get numDecimal() {
		return gdbx.numDecimal;
	}

	public get updatedTableFields() {
		const tableFieldClone = [...this.tableFields];

		const warehousePosition = tableFieldClone.findIndex(
			(field) => field.key === 'warehouse_qty',
		);

		if (!this.isShowDetails) {
			tableFieldClone.splice(warehousePosition, 4);
		}

		return tableFieldClone;
	}

	public get updatedTableItems() {
		const newItems: any[] = [];

		if (this.isIncludeInactive && this.isOnlyFilledNewReorder) {
			this.tableItems.filter((item) => {
				if (
					item.hasOwnProperty('order_decision') &&
					(item.order_decision || item.order_decision === 0)
				) {
					newItems.push(item);
				}
			});
			return newItems.length > 0 ? newItems : this.tableItems;
		} else if (this.isIncludeInactive && !this.isOnlyFilledNewReorder) {
			return this.tableItems;
		} else if (!this.isIncludeInactive && this.isOnlyFilledNewReorder) {
			this.tableItems
				.filter((item) => item.isActive === 'T')
				.filter(
					(item) => item.isDiscontinued !== 'T' || item.isDiscontinued === null,
				)
				.filter((item) => {
					if (
						item.hasOwnProperty('order_decision') &&
						(item.order_decision || item.order_decision === 0)
					) {
						newItems.push(item);
					}
				});

			return newItems.length > 0
				? newItems
				: this.tableItems
						.filter((item) => item.isActive === 'T')
						.filter(
							(item) =>
								item.isDiscontinued !== 'T' || item.isDiscontinued === null,
						);
		} else if (!this.isIncludeInactive && !this.isOnlyFilledNewReorder) {
			return this.tableItems
				.filter((item) => item.isActive === 'T')
				.filter(
					(item) => item.isDiscontinued !== 'T' || item.isDiscontinued === null,
				);
		}
	}

	public get expensiveHook() {
		const { selectedAsOfDate, selectedDateRange, selectedStockItems } = this;
		return JSON.stringify([
			selectedAsOfDate,
			selectedDateRange,
			selectedStockItems,
		]);
	}

	public async expensiveCalc() {
		const asOfStockTransRef = FilteredDatabase.ref('stockTrans')
			.dateAsOf(this.selectedAsOfDate)
			.stockItems(this.selectedStockItems);

		const asOfStockTransCards: StockTransCard[] = await (
			await asOfStockTransRef.get()
		).getCards();

		// Sales Order Ref
		const asOfSalesOrderRef = FilteredDatabase.ref('salesOrder')
			.dateAsOf(this.selectedAsOfDate)
			.stockItems(this.selectedStockItems);

		const asOfSalesOrderCards: SalesOrderDetailCard[] = await (
			await asOfSalesOrderRef.get()
		).getCards();

		// Purchase Order Ref
		const asOfPurchaseOrderRef = FilteredDatabase.ref('purchaseOrder')
			.dateAsOf(this.selectedAsOfDate)
			.stockItems(this.selectedStockItems);

		const asOfPurchaseOrderCards: PurchaseRequestOrder[] = await (
			await asOfPurchaseOrderRef.get()
		).getCards();

		// Purchase Request Ref
		const asOfPurchaseRequestRef = FilteredDatabase.ref('purchaseRequest')
			.dateAsOf(this.selectedAsOfDate)
			.stockItems(this.selectedStockItems);

		const asOfPurchaseRequestCards: PurchaseRequestOrder[] = await (
			await asOfPurchaseRequestRef.get()
		).getCards();

		const groupedSalesOrder = {};

		for (const item of asOfSalesOrderCards) {
			if (!groupedSalesOrder[item.itemCode]) {
				groupedSalesOrder[item.itemCode] = [];
			}
			groupedSalesOrder[item.itemCode].push(item);
		}

		const groupedPurchaseRequest = {};

		for (const item of asOfPurchaseRequestCards) {
			if (!groupedPurchaseRequest[item.itemCode]) {
				groupedPurchaseRequest[item.itemCode] = [];
			}
			groupedPurchaseRequest[item.itemCode].push(item);
		}

		const groupedPurchaseOrder = {};

		for (const item of asOfPurchaseOrderCards) {
			if (!groupedPurchaseOrder[item.itemCode]) {
				groupedPurchaseOrder[item.itemCode] = [];
			}
			groupedPurchaseOrder[item.itemCode].push(item);
		}

		const globalStockRef = FilteredDatabase.ref('globalStocks').stockItems(
			this.selectedStockItems,
		);

		const globalStockCards: StockDbCard[] = await (
			await globalStockRef.get()
		).getCards();

		const groupedAsOfItemCode = {};

		for (const item of asOfStockTransCards) {
			if (!groupedAsOfItemCode[item.itemCode]) {
				groupedAsOfItemCode[item.itemCode] = [];
			}
			groupedAsOfItemCode[item.itemCode].push(item);
		}

		// const groupedItemCode = {};

		// for (const item of stockTransCards) {
		// 	if (!groupedItemCode[item.itemCode]) {
		// 		groupedItemCode[item.itemCode] = [];
		// 	}
		// 	groupedItemCode[item.itemCode].push(item);
		// }

		const groupedGlobalItemCode = {};

		for (const item of globalStockCards) {
			if (!groupedGlobalItemCode[item.itemCode]) {
				groupedGlobalItemCode[item.itemCode] = [];
			}
			groupedGlobalItemCode[item.itemCode].push(item);
		}

		const result: Array<{
			item_desc: string;
			warehouse_qty: number;
			period_sales: number;
			daily_demand: number;
			inventory_days: number;
			lead_time_system: number;
			recommend_reorder_qty: number;
			order_decision: number | null;
			isActive: string;
			isDiscontinued: string | null;
		}> = [];

		for (const item in groupedAsOfItemCode) {
			if (
				groupedAsOfItemCode.hasOwnProperty(item) &&
				groupedGlobalItemCode.hasOwnProperty(item)
			) {
				const asOfCards = groupedAsOfItemCode[item] as StockTransCard[];
				const globalCards = groupedGlobalItemCode[item] as StockDbCard[];
				const purchaseOrderCards =
					(groupedPurchaseOrder[item] as PurchaseRequestOrder[]) || [];
				const purchaseRequestCards =
					(groupedPurchaseRequest[item] as PurchaseRequestOrder[]) || [];
				const salesOrderCards =
					(groupedSalesOrder[item] as SalesOrderDetailCard[]) || [];

				const quantity = asOfCards.reduce((a, b) => a + b.quantity, 0);
				const periodSales = asOfCards
					.filter(
						(a) =>
							moment(this.selectedAsOfDate).diff(a.date, 'days') <
							globalCards[0].leadTime,
					)
					.reduce((a, b) => a + Math.abs(b.quantityOut), 0);

				const object = {
					item_desc: gdbx.stockItemNames[item],
					shipping_qty: 0,
					so_qty: 0,
					ordered_qty: 0,
					warehouse_qty: 0,
					actual_qty: 0,
					period_sales: 0,
					daily_demand: 0,
					inventory_days: 0,
					recommend_reorder_qty: 0,
					lead_time_system: 0,
					order_decision: null,
					isActive: '',
					isDiscontinued: ('' as string) || null,
				};

				const dailyDemand =
					globalCards[0].leadTime !== 0
						? roundToFixed(
								periodSales / globalCards[0].leadTime,
								this.numDecimal,
						  )
						: 0;

				const shippingQuantity = purchaseOrderCards.reduce(
					(a, b) => a + b.osQuantity,
					0,
				);
				const soQuantity = salesOrderCards.reduce(
					(a, b) => a + b.outstandingQuantity,
					0,
				);

				const orderedQuantity = purchaseRequestCards.reduce(
					(a, b) => a + b.osQuantity,
					0,
				);

				const inventoryDays =
					dailyDemand > 0 ? Math.trunc(quantity / dailyDemand) : 0;

				const recommendOrder =
					Math.round(
						(Math.round(globalCards[0].leadTime * dailyDemand * 100) / 100 -
							(shippingQuantity + quantity + orderedQuantity - soQuantity)) *
							100,
					) / 100;
				object.shipping_qty = shippingQuantity;
				object.so_qty = soQuantity;
				object.ordered_qty = orderedQuantity;
				object.actual_qty =
					shippingQuantity + quantity + orderedQuantity - soQuantity;
				object.warehouse_qty = quantity;
				object.period_sales = periodSales;
				object.daily_demand = dailyDemand;
				object.inventory_days = inventoryDays;
				object.recommend_reorder_qty = recommendOrder;
				object.lead_time_system = globalCards[0].leadTime;
				object.isActive = globalCards[0].isActive;
				object.isDiscontinued = globalCards[0].isDiscontinued;

				result.push(object);
			}
		}

		this.tableItems = result;

		this.tableFields = [
			{
				key: 'item_desc',
				sortable: true,
				tdClass: (value) => {
					if (value) {
						return 'custom-style';
					}
				},
				stickyColumn: true,
			},
			{
				key: 'warehouse_qty',
				label: 'Warehouse Qty',
				sortable: true,
				variant: 'info',
				class: 'text-center',
			},
			{
				key: 'shipping_qty',
				label: 'Shipping',
				sortable: true,
				variant: 'info',
				class: 'text-center',
			},
			{
				key: 'so_qty',
				label: 'SO Qty',
				sortable: true,
				variant: 'info',
				class: 'text-center',
			},
			{
				key: 'ordered_qty',
				label: 'Ordered',
				sortable: true,
				variant: 'info',
				class: 'text-center',
			},
			{
				key: 'actual_qty',
				label: 'Actual Qty',
				sortable: true,
				class: 'text-center',
			},
			{
				key: 'lead_time_system',
				label: 'Lead Time',
				sortable: true,
				class: 'text-center',
			},
			{
				key: 'period_sales',
				sortable: true,
				class: 'text-center',
			},
			{
				key: 'daily_demand',
				sortable: true,
				class: 'text-center',
			},
			// {
			// 	key: 'reorder_level',
			// 	sortable: true,
			// 	sortByFormatted: (value, key, item) => {
			// 		const reorderLevel = item.lead_time_system * item.daily_demand;
			// 		return Math.round(reorderLevel * 100) / 100;
			// 	},
			// },
			{
				key: 'inventory_days',
				label: 'Inventory Days',
				sortable: true,
				// sortByFormatted: (value, key, item) => {
				// 	const sum =
				// 		item.daily_demand > 0 ? item.warehouse_qty / item.daily_demand : 0;
				// 	return Math.trunc(sum);
				// },
				class: 'text-center',
			},
			{
				key: 'recommend_reorder_qty',
				label: 'Recommend Order Qty',
				sortable: true,
				// sortByFormatted: (value, key, item) => {
				// 	const reorderLevel = item.lead_time_system * item.daily_demand;
				// 	return (
				// 		Math.round(
				// 			(Math.round(reorderLevel * 100) / 100 - item.warehouse_qty) * 100,
				// 		) / 100
				// 	);
				// },
				class: 'text-center',
			},
			{
				key: 'order_decision',
				label: 'Order Decision',
				sortable: false,
				class: 'text-center',
			},
		];
		this.saveHistory('tableItems', 'tableFields');
	}

	public onHovering(value: boolean, filterId: string) {
		this.$emit('hovering', value, filterId);
	}
	public onEditing(value: boolean, filterId: string) {
		this.$emit('editing', value, filterId);
	}
}
