
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import ReorderTable from '@/components/reports-v2/components/codedWidgets/recommendation/ReorderTable.vue';
import userx from '@/store/modules/userx';
import router from '@/router';
import LastSync from '@/components/LastSync.vue';
import SelectFilter from '@/components/reports-v2/components/filters/SelectFilter.vue';
import StickyFilterContainer from '@/components/reports-v2/components/pages/StickyFilterContainer.vue';
import StockListFilter from '@/components/reports-v2/components/filters/StockListFilter.vue';
import moment from 'moment';

@Component({
	components: {
		LastSync,
		ReorderTable,
		SelectFilter,
		StickyFilterContainer,
		StockListFilter,
	},
})
export default class Reorder extends Vue {
	public selectedStockItems: string[] = [];

	public mounted() {
		this.track();
	}

	public track() {
		this.$ga.set('userId', userx.user!.uid + '_' + userx.user!.email);
		this.$ga.page(router);
	}

	public get selectedAsOfDate() {
		return moment().endOf('day').valueOf();
	}

	public onHovering(value: boolean, filterId: string) {
		this.$emit('hovering', value, filterId);
	}
	public onEditing(value: boolean, filterId: string) {
		this.$emit('editing', value, filterId);
	}
}
